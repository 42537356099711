import React, { Component } from 'react';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [
                {
                    id: 1,
                    image: './images/blog/04.jpg',
                    alt: 'images',
                },
                {
                    id: 2,
                    image: './images/blog/09.jpg',
                    alt: 'images',
                },
                {
                    id: 3,
                    image: './images/blog/10.jpg',
                    alt: 'images',
                }
            ],
            testimonial: [
                {
                    id: 1,
                    text: '“I recently worked with Enrique on a React project and I was blown away by his expertise and efficiency. He quickly grasped the project requirements and delivered high-quality code that exceeded my expectations. I would highly recommend Enrique for any front-end development project”',
                    name: '',
                    office: ''
                },
                {
                    id: 2,
                    text: '“I had the privilege of working with Enrique on a complex React project and he did not disappoint. His deep understanding of modern web technologies such as Redux, GraphQL, and Next.js made him a valuable asset to our team. I would definitely work with him again”',
                    name: '',
                    office: ''
                },
                {
                    id: 3,
                    text: '“Enrique has been a great asset to our team. He has excellent skills in React and other web technologies, which has allowed him to create high-quality web applications for our clients. He is proactive and always looking for ways to improve his code and make it more efficient. If you need a skilled frontend developer, Enrique is the person for the job”',
                    name: '',
                    office: ''
                }
            ]
        }            
    }
    
    render() {
        return (
            <div id="c_clients" className="tf-modalbox-wrapper">
                <div className="tf-modal-content">
                    <section className="testimonial t2 s2 bg-s1 position-relative" id="testimonial">
                        <div className="background-right bg-cl2">
                        </div>
                        <div className="background-transparent bg-cl4"></div>
                        <div className="container d-lg-flex">                         
                            <div className="col-right animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                                <div className="flat-spacer" data-desktop={0} data-mobile={100} data-smobile={100} />
                                <div className="flat-title t1">
                                    <h4 className="sub-title mg-b13">Testimonial</h4>
                                    <h2 className="title-section color-d12">Client Brief</h2>
                                </div>
                                <div className="slider-drop-apps custom-dot dot-s1 none-nav-default mg-dots-s3">
                                    <div className="flat-carousel-box data-effect clearfix" data-gap={0} data-column={1} data-column2={1} data-column3={1} data-column4={1} data-column5={1} data-dots="true" data-auto="false" data-nav="false" data-loop="true">
                                        <div className="owl-carousel">
                                            {
                                                this.state.testimonial.map(data => (
                                                    <div className="client-info" key={data.id}>
                                                        <p className="color-d16">
                                                            {data.text}
                                                        </p>
                                                        <div className="client-detail">
                                                            <span className="color-d6 f-w500">{data.name}</span> {data.office}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </section>
                </div>
            </div>
        );
    }
}

export default Testimonial;
