import React, { Component } from 'react';

class ContentEducation extends Component {
    render() {
        return (
            <div className="row content-inside">
                <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                    <div className="experience-box t2 border-corner5 color-s1">
                        <div className="box-inner">
                        <h3 className="f-info color-d3">FIMC.COM (2022-2023) </h3>
                        <p>
                        Developed e-commerce application using React JS, Redux, Next JS, Node JS, HTML5, CSS3. Implemented redux and GraphQL, worked with JSON and RESTful operations, AZURE services. Used AGILE methodology with JIRA and GIT, Vercel for CI. Debugged and improved functionality, created pages using Next.JS.
                        </p>
                    </div>
                </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.6s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s1">
                        <div className="box-inner">
                            <h3 className="f-info color-d3">INMOMENT/WOOTRIC (2021-2022)</h3>
                            <p>
                            Developed new and existing features on React/Redux and Ruby on Rails/NodeJS applications, building new UI components and product features. Collaborated with design, product, and business teams on UI/UX decisions. Experience with containerization (Docker, Kubernetes) and cloud providers (Heroku, GCP, AWS).
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.7s">
                    <div className="experience-box t2 border-corner5 color-s2">
                        <div className="box-inner">
                        <h3 className="f-info color-d4">HERO DIGITAL (2020-2021)</h3>
                        <p>
                        As a lead operations and front-end developer, I oversaw the design and development of WordPress sites, ensuring projects met tight deadlines and scope. Skilled in PHP (LAMP stack), I executed PHP and HTML development outside of WordPress for various projects. I improved website functionality using strategic JavaScript frameworks and guided technical integration with third-party services.
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s2">
                        <div className="box-inner">
                            <h3 className="f-info color-d4">LEXISNEXIS (2017-2019)</h3>
                            <p>
                            Front-end web developer with expertise in HTML5, CSS3, MVC, Web Services, Bootstrap, SASS, LESS, Node.js, Git, Grunt, Bower, jQuery, and JavaScript. Led teams in the implementation of Bootstrap and Responsive Design for exceptional user experiences. Provided accessibility expertise and WCAG 2.0 AA and Section 508 compliance
                            </p>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.6s">
                    <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                        <h3 className="f-info color-d5">ALTERIA LASER (2016-2017)</h3>
                        <p>
                        Developed front-end for Laser Marker System's content management using HTML5, CSS3, React, jQuery, Bootstrap and Node.js. Led UI/UX design and ensured content quality and accessibility. Oversaw development of prototypes, mock-ups and custom solutions to resolve defects during testing.
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.7s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                            <h3 className="f-info color-d5">PERFORMANT FINANCIAL (2016)</h3>
                            <p>
                            Experienced web application developer with expertise in .NET, SQL, JavaScript, Angular, React, Bootstrap, HTML5, CSS3, and web service consumption. Led creation of visually sophisticated dashboards and layouts, collaborated with cross-functional teams to align project objectives and achieve business goals, and guided dev lifecycle from concept to launch.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.6s">
                    <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                        <h3 className="f-info color-d5">CARNIVAL CRUISES (2014-2016)</h3>
                        <p>
                        Led front-end dev of Carnival Cruises Australia Registration & Booking site. Spearheaded visual design process, developing final look & feel with focus on layout, hierarchy, typography, color theory, and patterns.
                        </p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.7s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                            <h3 className="f-info color-d5">LEXISNEXIS (2012-2014)</h3>
                            <p>
                            Lead Front-end Dev for LexisNexis, directing visual design and serving as primary contact for Frontend/UI on technical dev team. Expert in Agile methodology and web frontend dev using HTML5, CSS, AJAX, JavaScript, jQuery mobile, and Bootstrap. 
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.6s">
                    <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                        <h3 className="f-info color-d5">IBM (2011-2012)</h3>
                        <p>
                        eLearning Developer with experience designing instructional material for customer training courses and creating accessible, compliant eLearning content with storyboards. Proficient in UI design and development, courseware format, and objective hierarchy maintenance.
                        </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6 col-12 animate-element wow delay5 fadeInUp" data-wow-delay="0.7s">
                    <div className="col-right">
                        <div className="experience-box t2 border-corner5 color-s3">
                        <div className="box-inner">
                            <h3 className="f-info color-d5">CITRIX SYSTEMS (2008-2011)</h3>
                            <p>
                            Web Developer with experience designing & developing online solutions for CITRIX social media sites using OOP, Agile, SCRUM, and GWT. Conceptualized and designed rich internet apps, crafted and maintained websites, and ensured ease of navigation. Proficient in Agile/SCRUM.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>

            </div>
        );
    }
}

export default ContentEducation;
