import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="footer footer-s1">
                <div id="bottom" className="bottom-s2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="copyright lt-sp02">
                                © Enrique Gollas, 2023
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
