import React, { Component } from 'react';

class AboutMe extends Component {
    render() {
        return (
            <div className="col-lg-7 animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                <div className="flat-title t1">
                    <h4 className="sub-title mg-b22">About Me</h4>
                    <h2 className="title-section color-d12 mg-b41">Elevating your digital experience, one project at a time.</h2>
                    <div className="description">
                        <p className="lt-sp01">
                            Hi, I'm Enrique, a Senior Frontend Developer with over 15 years of experience crafting exceptional websites and applications. I've had the opportunity to work with industry leaders like LexisNexis, IBM, Carnival Cruises, and Citrix, delivering user-friendly, high-performance solutions that exceed client expectations.
                        </p>
                        <p className="lt-sp01">
                            My expertise lies in modern frontend technologies such as ReactJS and Redux, with a strong foundation in APIs and NodeJS tools. I thrive in collaborative environments, working closely with cross-functional teams to ensure seamless integration and functionality. With a sharp eye for detail and a deep understanding of web development best practices, I’m always honing my skills to stay ahead in the fast-evolving tech world.

Whether you're looking to build scalable, intuitive applications or tackle complex system integrations, I’m here to bring your vision to life. Let's create something great together!                        </p>
                    </div>
                    <h3 className="email position-relative">egollas@gmail.com</h3>
                </div>
            </div>
        );
    }
}

export default AboutMe;
