import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class SlideCase extends Component {
constructor(props) {
super(props);
this.state = {
slider: [
{
id: 1,
image: './images/section/45.jpg',
alt: 'images',
title: 'INMOMENT',
view: 'Full view',
tech: 'React, Ruby On Rails',
url: 'https://inmoment.com/'
},
{
id: 2,
image: './images/section/27.jpg',
alt: 'images',
title: 'HERO DIGITAL',
view: 'Full view',
tech: 'Wordpress, React',
url: 'https://meraki.cisco.com/'
},
{
id: 3,
image: './images/section/28.jpg',
alt: 'images',
title: 'FIMC.com',
view: 'Full view',
tech: 'React, Graph QL, Hygraph, Azure',
url: 'https://www.myrealbenefits.com/'
},
{
    id: 5,
    image: './images/section/sony.jpg',
    alt: 'images',
    title: 'SONY LATIN AMERICA',
    view: 'Full view',
    tech: 'HTML, CSS, Javascript, Wordpress',
    url: 'https://www.sony-latin.com/'
},
{
id: 6,
image: './images/section/citrix.jpg',
alt: 'images',
title: 'CITRIX',
view: 'Full view',
tech: 'HTML, CSS, Javascript, PHP, Social API integration',
url: 'https://www.citrix.com/community/'
},    



        ]
    }
}

render() {
    return (
        <div className="animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
            <div className="flat-carousel-box data-effect clearfix" data-gap={67} data-column={4} data-column2={3} data-column3={2} data-column4={1} data-column5={1} data-dots="true" data-auto="false" data-nav="false" data-loop="true">
                <div className="owl-carousel">
                    {
                        this.state.slider.map(data => (
                            <div className="image-box-t1" key={data.id}>
                                <div className="featured-post">
                                    <img src={data.image} alt={data.alt} />
                                </div>
                                <h3 className="name">{data.title}</h3>
                                <p>{data.tech}</p>
                                <div className="full-view">
                                <a href={data.url} target="_blank">{data.view}</a>
                                </div>
                            </div>
                        ))
                    }                      
                </div>
            </div>
      </div>
    );
}
}

export default SlideCase;